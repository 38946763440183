body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  background-color: #fff !important;
}

#root {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

a, a:active, a:hover {
  color: #1D79DE;
  outline: 0;
  font-weight: normal;
  text-decoration: none;
}

html {
  font-family: Roboto, sans-serif;
}
